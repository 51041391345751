<template lang="pug" functional>
  svg(
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg")
    path(
      d="M8.93604 4.70178V1.63731C8.93604 1.06187 9.64083 0.782242 10.0368 1.2006L15.884 7.37878C16.0387 7.54219 16.0387 7.79765 15.884 7.96106L9.96479 14.2153C9.5789 14.623 8.8915 14.369 8.86477 13.8088L8.72358 10.8501C6.52718 10.6674 3.85773 11.1142 1.39446 14.9016C0.996449 15.5136 0.0237342 15.3364 0.00518679 14.607C-0.198254 6.60744 5.63157 4.63712 8.93604 4.70178Z"
      fill="currentColor")

</template>

<script>
export default {
  name: 'IconReset'
}
</script>

<style lang="scss">
</style>
